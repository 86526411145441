/* eslint-disable react/function-component-definition */

export default function isDarkModeSupportedPage(page: string) {
  return [
    "/",
    "/financials",
    "/compare",
    "/coupons",
    "/issuers",
    "/discussions",
    "/fatos-relevantes",
    "/ranking",
    "/comments",
    "/us/[tickerSymbol]/kpis",
    /^\/us\/([a-zA-Z]{0,10})\/kpis/i,
  ].some((candidate) => {
    if (candidate instanceof RegExp) {
      return candidate.test(page);
    }
    return candidate.length === 1 ? page === candidate : page.startsWith(candidate);
  });
}
